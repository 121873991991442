<template>
  <div class="findidpass_main_app">
    <v-container class="login_main_body">
      <v-snackbar
        v-model="snackbar.visible"
        :color="snackbar.color"
        :multi-line="snackbar.mode === 'multi-line'"
        :timeout="snackbar.timeout"
        :top="snackbar.position === 'top'"
        rounded="pill"
        width="877.27px"
      >
        <v-layout align-center pr-4>
          <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
            <div>{{ $t(snackbar.text) }}</div>
          </v-layout>
        </v-layout>
        <v-btn
          v-if="snackbar.timeout === 0"
          icon
          @click="snackbar.visible = false"
        >
          <v-icon>clear</v-icon>
        </v-btn>
      </v-snackbar>
      <div class="row justify-content-center align-items-center">
        <div
          class="admin_main_row col-xl-3 col-lg-4 col-md-6 col-sm-10 col-xs-12 mt-200"
        >
          <div class="login_tab_nav_item row text-center">
            <div class="col-md-12">
              <ul
                class="nav login_tab_nav_item_ul"
                id="myTab"
                role="tablist"
                style="width: 100%"
              >
                <li
                  style="width: 50%"
                  class="nav-item"
                  role="presentation"
                  :class="{ active: isfindIdActive }"
                >
                  <button
                    style="width: 100%"
                    class="nav-link"
                    @click="findIdNav"
                  >
                    {{ $t("findIdPass.findID") }}
                  </button>
                </li>
                <li
                  style="width: 50%"
                  class="nav-item"
                  role="presentation"
                  :class="{ active: isfindPassActive }"
                >
                  <button
                    style="width: 100%"
                    class="nav-link"
                    @click="findPasswordNav"
                  >
                    {{ $t("findIdPass.findPassword") }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="row mt-30">
            <div class="col-md-12 mb-4">
              <div id="app">
                <div class="tab-content" id="myTabContent">
                  <div v-if="findIdPasstoggle === 'findIdroot'">
                    <div v-if="findIdKey === 'findId'">
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container>
                          <v-row class="login_form_row_tab">
                            <v-col>
                              <!-- Removed coz Qa Suggestion
                                                            <v-text-field v-model="user.username"
                                                                class="login_textfield_label" :rules="nameRules"
                                                                counter="5" :label="$t('findIdPass.name')" required
                                                                filled rounded dense>
                                                            </v-text-field> -->
                              <v-text-field
                                v-model="user.username"
                                class="login_textfield_label"
                                :rules="nameRules"
                                :label="$t('findIdPass.name')"
                                :placeholder="$t('findIdPass.nameplaceholder')"
                                required
                                filled
                                rounded
                                dense
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="login_form_row_tab2">
                            <v-col>
                              <!-- Removed coz Qc Suggestion -->
                              <!-- <v-text-field class="login_textfield_label"
                                                                :label="$t('findIdPass.licenceNumber')"
                                                                v-model="user.licenseNumber" @keyup.enter="findId"
                                                                :rules="licenseNumberRules" required filled rounded
                                                                dense>
                                                            </v-text-field> -->
                              <v-text-field
                                class="login_textfield_label"
                                :label="$t('findIdPass.licenceNumber')"
                                :placeholder="
                                  $t('findIdPass.licenceNumberplaceholder')
                                "
                                v-model="user.licenseNumber"
                                @keyup.enter="findId"
                                required
                                filled
                                rounded
                                dense
                              >
                              </v-text-field>
                              <!-- <span class="ms-3 span_validation_msg_tab">*License number
                                                                must
                                                                be
                                                                11
                                                                digits.</span> -->
                            </v-col>
                          </v-row>
                          <v-row class="login_form_row_tab mt-50">
                            <v-col>
                              <v-btn
                                class="login_button_down"
                                type="button"
                                @click="findId()"
                                rounded
                                >{{ $t("findIdPass.ok") }}
                              </v-btn>
                            </v-col>
                            <v-col>
                              <v-btn
                                class="login_button_down_button_first"
                                @click="findIdCancelbtn()"
                                rounded
                              >
                                {{ $t("findIdPass.cancel") }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </div>
                    <div v-else-if="findIdKey === 'showId'">
                      <div class="">
                        <div class="">
                          <div class="modal-body pt-5 text-center p-6">
                            <p>
                              {{ $t("findIdPass.idfindingMessage") }} <br />
                              {{ $t("findIdPass.theIDis") }}
                              {{ this.user.email }} 입니다.
                            </p>
                          </div>
                          <div
                            class="login_dialog_tab_footer_first modal-footer"
                          >
                            <v-btn
                              type="button"
                              style="color: white"
                              class="login_button_down"
                              @click="goLogin"
                            >
                              {{ $t("findIdPass.gotoLogin") }}</v-btn
                            >
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-xl-5  col-lg-6 col-md-8 col-11 ">
                                                ID finding is complete.
                                                The ID is {{ this.user.email }}.
                                            </div> -->
                    </div>
                  </div>
                  <div v-else-if="findIdPasstoggle === 'findPasswordroot'">
                    <div v-if="findPasswordKey === 'findPassword'">
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container>
                          <v-row class="login_form_row_tab">
                            <v-col>
                              <!-- Removed coz Qa Suggestion
                                                            <v-text-field class="login_textfield_label"
                                                                v-model="user.username" :rules="nameRules" counter="5"
                                                                :label="$t('findIdPass.name')" required filled rounded
                                                                dense>
                                                            </v-text-field> -->
                              <v-text-field
                                class="login_textfield_label"
                                v-model="user.username"
                                :rules="nameRules"
                                :label="$t('findIdPass.name')"
                                :placeholder="$t('findIdPass.nameplaceholder')"
                                required
                                filled
                                rounded
                                dense
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="login_form_row_tab2">
                            <v-col>
                              <v-text-field
                                class="login_textfield_label"
                                v-model="user.email"
                                :rules="emailRules"
                                :label="$t('findIdPass.email')"
                                :placeholder="$t('findIdPass.emailplaceholder')"
                                @keyup.enter="findPassword"
                                required
                                filled
                                rounded
                                dense
                              >
                              </v-text-field>
                              <!-- <span class="ms-3 span_validation_msg_tab">*ID must be in
                                                                email
                                                                format
                                                                do.</span> -->
                            </v-col>
                          </v-row>
                          <v-row class="login_form_row_tab mt-50">
                            <v-col>
                              <v-btn
                                class="login_button_down"
                                rounded
                                type="button"
                                @click="findPassword()"
                                >{{ $t("findIdPass.ok") }}
                              </v-btn>
                            </v-col>
                            <v-col>
                              <v-btn
                                class="login_button_down_button_first"
                                @click="findPasswordCancelbtn()"
                                rounded
                              >
                                {{ $t("findIdPass.cancel") }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </div>
                    <div v-else-if="findPasswordKey === 'showPassword'">
                      <div class="">
                        <div class="">
                          <div class="modal-body pt-5 text-center p-6">
                            <p>
                              {{ this.user.email }}
                              {{
                                $t("findIdPass.showPasswordEmailSentMessage")
                              }}
                              <br />
                              {{
                                $t("findIdPass.showPasswordtempPasswordEmail")
                              }}
                            </p>
                          </div>
                          <div
                            class="login_dialog_tab_footer_second modal-footer"
                          >
                            <v-btn
                              type="button"
                              style="color: white"
                              class="login_button_down"
                              data-bs-dismiss="modal"
                              @click="goLogin"
                            >
                              {{ $t("findIdPass.ok") }}</v-btn
                            >
                          </div>
                          <div
                            class="modal-footer mt-6 pt-3 justify-content-center"
                            style="align-items: center !important"
                          >
                            <p class="text-center">
                              {{ $t("findIdPass.didntgetEmail") }} <br />
                              <center>
                                <u>
                                  <a
                                    class="btn-resend"
                                    @click="resendemailSentApi"
                                    >{{ $t("findIdPass.resend") }}</a
                                  ></u
                                >
                              </center>
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-xl-5  col-lg-6 col-md-8 col-11 ">
                                                An email has been sent to the {{ this.user.email }} address.<br />
                                                Please log in with the temporary password included in the email.
                                            </div>
                                            <v-btn type="button" class="find_id_password_form_btn btn" @click="goLogin">
                                                Ok
                                            </v-btn>
                                            <br />
                                            <p>Didn't you get an email?<br />
                                                <a @click="emailSentApi">Resend</a>
                                            </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
    <!-- Removed coz of Suggestion
        <footer class="mt-80 main_footer bg-light text-center text-lg-start">
            <div class="text-start p-2">
                <img class="footer_logo_main ms-3" src="@/assets/images/footer_logo.png" alt="">
            </div>
        </footer> -->
  </div>
</template>
<script>
// import '@/assets/css/bootstrap.min.css'
import "@/assets/css/style.css";
import "@/assets/jquery/jquery.min.js";
// import '@/assets/js/bootstrap.bundle.js'
import "@/assets/js/vue.js";
// import '@/assets/js/vuetify.js'
import "@/assets/css/vuetify.min.css";
import MemberDataService from "@/services/MemberDataService";
// import { items } from '~/src/data/navigation'
import { randompwd } from "@/utils/utils";
export default {
  name: "FindIdPass",
  props: ["items"],
  data() {
    return {
      user: {
        id: null,
        username: "",
        email: "",
        licenseNumber: "",
        password: "",
        createdAt: "",
        updatedAt: "",
      },
      isfindIdActive: true,
      isfindPassActive: false,
      findIdPasstoggle: "findIdroot",
      valid: true,
      findIdKey: "findId",
      findPasswordKey: "findPassword",
      buttons: [
        {
          color: "warning",
          title: "Warning",
          type: "warning",
        },
      ],
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 2500,
        title: null,
        visible: false,
      },
    };
  },
  methods: {
    findId() {
      this.$refs.form.validate();
      if (this.user.username == "" || this.user.licenseNumber == "") {
        if (this.user.username == "") {
          this.SnackbarShow("username");
          return false;
        } else if (this.user.password == "") {
          this.SnackbarShow("licenseNumber");
          return false;
        }
      }
      if (this.$refs.form.validate()) {
        MemberDataService.findId(this.user)
          .then((response) => {
            if (response.data != false) {
              console.log(response.data.email);
              this.user.email = response.data.email;
              this.findIdKey = "showId";
              // this.user = ''
            } else {
              this.SnackbarShow("mismatchNameLicenseNumber");
              return false;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        return false;
      }
    },
    findPassword() {
      this.$refs.form.validate();
      if (this.user.username == "" || this.user.email == "") {
        if (this.user.username == "") {
          this.SnackbarShow("username");
          return false;
        } else if (this.user.email == "") {
          this.SnackbarShow("email");
          return false;
        }
      }
      if (this.$refs.form.validate()) {
        MemberDataService.findPassword(this.user)
          .then((response) => {
            if (response.data != false) {
              this.user.id = JSON.stringify(response.data.id);
              this.user.password = randompwd();
              console.log("random pwd is : " + this.user.password);
              this.emailSentApi();
              this.findPasswordKey = "showPassword";
              //this.user = ''
            } else {
              this.SnackbarShow("mismatchNameEmail");
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    findIdCancelbtn() {
      this.user.username = "";
      this.user.email = "";
      this.user.password = "";
      this.user.licenseNumber = "";
      this.$router.push("login");
    },
    findPasswordCancelbtn() {
      this.user.username = "";
      this.user.email = "";
      this.user.password = "";
      this.user.licenseNumber = "";
      this.$router.push("login");
    },
    emailSentApi() {
      MemberDataService.emailSent(this.user)
        .then((response) => {
          console.log("Email Data : " + response.data);
          return true;
        })
        .catch((e) => {
          console.log("Email Sent Failed");
          console.log(e);
          return false;
        });
    },
    resendemailSentApi() {
      MemberDataService.emailSent(this.user)
        .then((response) => {
          console.log("Email Data : " + response.data);
          this.SnackbarShow("resendMessage");
          return true;
        })
        .catch((e) => {
          console.log("Email Sent Failed");
          console.log(e);
          return false;
        });
    },
    goLogin() {
      this.user.username = "";
      this.user.email = "";
      this.user.password = "";
      this.user.licenseNumber = "";
      this.$router.push("login");
    },
    findIdNav() {
      this.isfindPassActive = false;
      this.isfindIdActive = true;
      this.findIdPasstoggle = "findIdroot";
      (this.findIdKey = "findId"), (this.user.username = "");
      this.user.email = "";
      this.user.password = "";
      this.user.licenseNumber = "";
    },
    findPasswordNav() {
      this.isfindIdActive = false;
      this.isfindPassActive = true;
      this.findPassClass = "nav-link active";
      this.findIdPasstoggle = "findPasswordroot";
      (this.findPasswordKey = "findPassword"), (this.user.username = "");
      this.user.email = "";
      this.user.password = "";
      this.user.licenseNumber = "";
    },
    SnackbarShow(type) {
      switch (type) {
        case "username":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "findIdPass.nameValidation",
            visible: true,
          };
          break;
        case "licenseNumber":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "findIdPass.licenseNumberValidation",
            visible: true,
          };
          break;
        case "email":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "findIdPass.emailValidation",
            visible: true,
          };
          break;
        case "mismatchNameLicenseNumber":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "findIdPass.mismatchNameLicenseNumberValidation",
            visible: true,
          };
          break;
        case "mismatchNameEmail":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Warning",
            text: "findIdPass.mismatchNameEmailValidation",
            visible: true,
          };
          break;
        case "resendMessage":
          this.snackbar = {
            color: "#FF5252",
            icon: "mdi-check-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 2000,
            title: "Success",
            text: "findIdPass.resendTempPasswordMessage",
            visible: true,
          };
          break;
      }
    },
  },
  computed: {
    nameRules() {
      return [(v) => v.length <= 5 || this.$t("findIdPass.nameRulesMessage")];
    },
    licenseNumberRules() {
      return [
        (v) =>
          v.length == 11 || this.$t("findIdPass.licenceNumberRulesMessage"),
      ];
    },
    emailRules() {
      // return [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('findIdPass.emailRulesMessage')];
      return [
        (v) =>
          !v ||
          // /^[-a-zA-Z0-9_.]+@[-a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/.test(v) ||
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("findIdPass.emailRulesMessage"),
      ];
    },
  },
  beforeMount() {
    this.user.username = "";
    this.user.email = "";
    this.user.password = "";
    this.user.licenseNumber = "";
  },
  mounted() {
    if (this.$route.params.items === "FindId") {
      this.findIdNav();
    }
    if (this.$route.params.items === "FindPass") {
      this.findPasswordNav();
    }
  },
};
</script>

<style lang="scss">
.v-text-field__slot {
  margin-bottom: 5px !important;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  // color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-link:focus,
.nav-link:hover {
  color: #c5203f;
}

.nav-item .active {
  color: #c5203f !important;
}

// body {
//     background-color: white !important;
// }

.main_footer {
  background-color: #f8f9fa !important;
}

.p-2 {
  padding: 0.5rem !important;
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem) !important;
  }
}

.modal-dialog-centered {
  display: flex !important;
  align-items: center !important;
  min-height: calc(100% - 1rem) !important;
}

.modal-dialog {
  position: relative !important;
  width: auto !important;
  margin: 0.5rem !important;
  pointer-events: none !important;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px !important;
    /* margin: 30px auto; */
  }
}

.modal-dialog {
  position: relative !important;
  width: auto !important;
  margin: 10px !important;
}

.modal-content {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  pointer-events: auto !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.3rem !important;
  outline: 0 !important;
}

.modal-body {
  position: relative !important;
  flex: 1 1 auto !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.p-6 {
  padding: 1rem !important;
}

.login_dialog_tab_footer_first {
  justify-content: center !important;
  border-top: none !important;
}

.modal-footer {
  // display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  // justify-content: flex-end;
  // padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  .btn-resend {
    color: #000000;
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

@media screen and (min-width: 1264px) and (max-width: 1462px) {
  .login_tab_nav_item_ul li {
    padding: 0px 17px;
  }
}

@media only screen and (max-width: 768px) {
  .findidpass_main_app {
    padding: 20px !important;
  }
  ul.login_tab_nav_item_ul {
    padding-left: 0;
  }
}

@media only screen and (max-width: 516px) {
  .login_tab_nav_item_ul li {
    padding: 0px 10px;
  }

  .login_tab_nav_item .nav-item {
    font-size: 15px;
  }
}

@media only screen and (max-width: 367px) {
  .login_tab_nav_item .nav-item {
    font-size: 12px;
  }

  .login_tab_nav_item_ul li button {
    // padding: 0;
  }
}
</style>