export const randompwd = () => {
    let pass = "";
    // let str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
    //     'abcdefghijklmnopqrstuvwxyz' + '0123456789' + '@$!%*?&';
    let str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let pwdUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let pwdLower = "abcdefghijklmnopqrstuvwxyz";
    let pwdDigit = "01234567890123456789012456";
    let pwdSpecialChar = "@$!%*?&@$!%*?&@$!%*?&@$!%*";

    for (let i = 1; i <= 2; i++) {
        let char = Math.floor(Math.random() * str.length);
        console.log("char1 is : " + (str.length -1) + " " + char);
        pass += pwdUpper.charAt(char);
        char = Math.floor(Math.random() * str.length);
        console.log("char2 is : " + (str.length -1) + " " + char);
        pass += pwdLower.charAt(char);
        char = Math.floor(Math.random() * str.length);
        console.log("char3 is : " + (str.length -1) + " " + char);
        pass += pwdDigit.charAt(char);
        char = Math.floor(Math.random() * str.length);
        console.log("char4 is : " + (str.length -1) + " " + char);
        pass += pwdSpecialChar.charAt(char);
    }

    return pass;
}